import {
  MVP100KTierName,
  MVPTierName,
  MVPGoldTierName,
  MVPGold75KTierName,
  MPChoiceProductName,
  AvisStatusMatchProductName,
  AccountOverviewPath,
  MPPerks,
  MemberInvalidTierPromotions,
} from "../../helpers/constants";
import { AccountStateType } from "../../state/InitialState";

const redirect = (path: string) => {
  window.location.href = path;
  return null;
};

const ProtectedRoute = ({
  data,
  product,
  children,
}: {
  data: AccountStateType;
  product: string;
  children: JSX.Element;
}): any => {
  const user = data.MileagePlanDetails;

  // Checking access to Avis Status Match Product Routes
  if (product === AvisStatusMatchProductName) {
    if (
      user.MileagePlanNumber &&
      (user.MilestoneName === MVPGoldTierName ||
        user.MilestoneName === MVP100KTierName ||
        user.MilestoneName === MVPGold75KTierName ||
        user.MilestoneName === MVPTierName)
    ) {
      return children;
    } else {
      return redirect(AccountOverviewPath);
    }
  }
  // Checking access to Perks Routes
  if (product === MPPerks) {
    if (
      user.MileagePlanNumber
    ) {
      return children;
    } else {
      return redirect(AccountOverviewPath);
    }
  }
  // Checking access to 100K Choice Product Routes
  else if (
    product === MPChoiceProductName &&
    user.MilestoneName === MVP100KTierName &&
    data.MileagePlanDetails.IsEligibleFor100KChoiceSelection
  ) {
    return children;
  } else {
    return redirect(AccountOverviewPath);
  }
};
export default ProtectedRoute;
