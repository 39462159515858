const initialState = {
  Token: {
    Value: "",
    Guid: "",
  },
  MVPGoldNominationDetails: {
    FirstName: "", 
    LastName: "",
    MileagePlanNumber: "",
    Email: ""
  },
  MileagePlanDetails: {
    MileagePlanNumber: "",
    FirstName: "",
    LastName: "",
    Miles: "",
    MemberSelectionId: 0,
    MilestoneId: 0,
    MilestoneName: "",
    Email: "",
    MP100KChoice: "",
    MemberBalance: 0,
    MilestoneAchievmentDate: "",
    SelectionExpirationDate: "",
    IsEligibleFor100KChoiceSelection: false,
    Perks: [{PerkBenefitId : null, PerkName: "", PerkDescription: "", isEnabled: null}]
  },
  isSuperUser: false,
  Error: { message: null },
  SelectedChoiceMade: "",
  NominatedName: "",
};

export type AccountStateType = typeof initialState;

export default initialState;
