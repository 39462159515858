
import reportWebVitals from "./reportWebVitals";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import MileagePlanData from "./hooks/MPChoiceService";
import { BrowserRouter } from "react-router-dom";
import { OptimizelyProvider, createInstance } from "@optimizely/react-sdk";
import { createRoot } from "react-dom/client";

// Instantiate an Optimizely client
const optimizely = createInstance({
  sdkKey: process.env.REACT_APP_OPTIMIZELY_SDK_KEY,
});

const root = createRoot(document.getElementById('root')!);
root.render(<OptimizelyProvider optimizely={optimizely} user={{ id: process.env.REACT_APP_OPTIMIZELY_ID as string }}>
  <BrowserRouter>
    <App data={MileagePlanData} />
  </BrowserRouter>
</OptimizelyProvider>);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
