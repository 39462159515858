import "./App.scss";
import { Route, Routes } from "react-router-dom";
import "./webcomponents";
import Spinner from "./components/Spinner";
import { reactPlugin } from "./services/AppInsights";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import MVPGoldNominationPage from "./components/MVPGoldNominationPage";
import {
  MVPGoldFormPath,
  ThankYouPagePath,
  MPChoiceProductName,
  AvisStatusMatchProductName,
  ABGFormPath,
  MP100KPath,
  ABGConfirmationPath,
  MPPerks
} from "./helpers/constants";
import MPThankYouPage from "./components/MPThankYouPage/MPThankYouPage";
import mountHeaderFooter from "./services/header-footer/index";
import UserAccountContext from "./context/UserAccountContext";
import ABGContainer from "./components/ABGContainer";
import ABGConfirmationPage from "./components/ABGConfirmationPage";
import ProtectedRoute from "./components/ProtectedRoute";
import MP100KContainer from "./components/MP100KContainer/MP100KContainer";
import { OptimizelyFeature } from "@optimizely/react-sdk";
import MPPerksContainer from "./components/MPPerksContainer";

const App = (props: { data: any }) => {
  mountHeaderFooter(
    document.getElementById("alaska-header"),
    document.getElementById("alaska-footer")
  );

  let mileagePlanData = props.data();
  let mileagePlanNumber =
      mileagePlanData.accountState.MileagePlanDetails.MileagePlanNumber;

  // Product Access Levels:
  // All routes except Avis Status Match T&Cs behind User Auth
  // Avis Status Match --> MVP, Gold, 75k, 100k
  // MP Choice --> 100k & have not made choice yet
  // if (mileagePlanData.accountState.MileagePlanDetails.MileagePlanNumber !== "") {
  if (mileagePlanNumber !== "") {
    return (
        <UserAccountContext.Provider value={mileagePlanData}>
          <Routes>
            <Route
                path={MP100KPath}
                element={
                  <ProtectedRoute
                      data={mileagePlanData.accountState}
                      product={MPChoiceProductName}
                  >
                    <OptimizelyFeature feature="100k_choice">
                      {(isEnabled, variablesOptimizely100K) =>
                          isEnabled &&
                          (variablesOptimizely100K.mem_num.includes(
                                  mileagePlanNumber
                              ) ||
                              variablesOptimizely100K.openToAll) ? (
                              <>
                                <AppInsightsContext.Provider value={reactPlugin}>
                                  <MP100KContainer />
                                </AppInsightsContext.Provider>
                              </>
                          ) : (
                              `We are currently upgrading this 100K Choice experience. Stay tuned for an email notification when it's complete!`
                          )
                      }
                    </OptimizelyFeature>
                  </ProtectedRoute>
                }
            />
            <Route
                path={MVPGoldFormPath}
                element={
                  <ProtectedRoute
                      data={mileagePlanData.accountState}
                      product={MPChoiceProductName}
                  >
                    <OptimizelyFeature feature="100k_choice">
                      {(isEnabled, variablesOptimizely100K) =>
                          isEnabled &&
                          (variablesOptimizely100K.mem_num.includes(
                                  mileagePlanNumber
                              ) ||
                              variablesOptimizely100K.openToAll) ? (
                              <>
                                <AppInsightsContext.Provider value={reactPlugin}>
                                  <MVPGoldNominationPage />
                                </AppInsightsContext.Provider>
                              </>
                          ) : (
                              `We are currently upgrading this 100K Choice experience. Stay tuned for an email notification when it's complete!`
                          )
                      }
                    </OptimizelyFeature>
                  </ProtectedRoute>
                }
            />
            <Route
                path={ThankYouPagePath}
                element={
                  <ProtectedRoute
                      data={mileagePlanData.accountState}
                      product={MPChoiceProductName}
                  >
                    <OptimizelyFeature feature="100k_choice">
                      {(isEnabled, variablesOptimizely100K) =>
                          isEnabled &&
                          (variablesOptimizely100K.mem_num.includes(
                                  mileagePlanNumber
                              ) ||
                              variablesOptimizely100K.openToAll) ? (
                              <>
                                <AppInsightsContext.Provider value={reactPlugin}>
                                  <MPThankYouPage />
                                </AppInsightsContext.Provider>
                              </>
                          ) : (
                              `We are currently upgrading this 100K Choice experience. Stay tuned for an email notification when it's complete!`
                          )
                      }
                    </OptimizelyFeature>
                  </ProtectedRoute>
                }
            />
            <Route
                path={ABGFormPath}
                element={
                  <ProtectedRoute
                      data={mileagePlanData.accountState}
                      product={AvisStatusMatchProductName}
                  >
                    <ABGContainer />
                  </ProtectedRoute>
                }
            />
            <Route
              path={MPPerks}
              element={
                <ProtectedRoute
                  data={mileagePlanData.accountState}
                  product={MPPerks}
                >
                  <OptimizelyFeature feature="perks">
                    {(isEnabled, variablesDisplayPerks) =>
                      isEnabled && (variablesDisplayPerks) ? (
                        <>
                          <AppInsightsContext.Provider value={reactPlugin}>
                            <MPPerksContainer />
                          </AppInsightsContext.Provider>
                        </>
                      ) : (
                        `We are currently upgrading the Perks experience. Stay tuned for an email notification when it's complete!`
                      )
                    }
                  </OptimizelyFeature>
                </ProtectedRoute>
              }
            />
            <Route
                path={ABGConfirmationPath}
                element={
                  <ProtectedRoute
                      data={mileagePlanData.accountState}
                      product={AvisStatusMatchProductName}
                  >
                    <ABGConfirmationPage />
                  </ProtectedRoute>
                }
            />
          </Routes>
        </UserAccountContext.Provider>
    );
  } else {
    return <Spinner />;
  }
};

export default App;
