import { useState, useEffect } from "react";
import toggleInterruption from "../../services/toggleInterruption";
import MPBenefitChoiceCard from "../MPBenefitChoiceCard/index";
import ChoiceCardContent from "../../helpers/ChoiceCardContent";
import MP100KAdditionalInfo from "../MP100KAdditionalInfo/MP100KAdditionalInfo";
import MPBenefitChoiceButton from "../MPBenefitChoiceButton";
import MP100KGuestOverview from "../MP100KGuestOverview";
import TimerWrapper from "../TimerWrapper";
import { waitForUtagAndTrack, createUtagObject, addTaggingUtil } from "../../helpers/tagging/tagging-utils";
import strings from "../../helpers/tagging/strings";
import useProductPageTitle from "../../hooks/useProductPageTitle";
import { BasePageTitle, MpChoiceProductPageTitle } from "../../helpers/constants";
import "./styles.scss";
import "@alaskaairux/auro-radio";
import "@alaskaairux/auro-radio/dist/auro-radio-group";

function MP100KContainer() {
  const [selectedChoice, setData] = useState('');
  const [errorResult, setResultData] = useState(false);

  setTimeout(() => toggleInterruption("#timeoutDialog"), 1000);
  useProductPageTitle(`${MpChoiceProductPageTitle}${BasePageTitle}`);

  useEffect(() => {
    if (errorResult) {
      let { page_name, channel, events } = strings.tags.mpchoice_error.load;
      waitForUtagAndTrack(createUtagObject(page_name, channel, events));
    } else {
      let { page_name, channel, events } = strings.tags.mpchoice_landing.load;
      waitForUtagAndTrack(createUtagObject(page_name, channel, events));
    }
    addTaggingUtil();
  }, [errorResult]);

  return (
      <div>
        <div className="overviewContainer">
          <div className="App container">
            <TimerWrapper />
            <MP100KGuestOverview />
          </div>
        </div>
        <div className="App container">
          {errorResult && (
              <div className="choiceAlert" id="ErrorMessage">
                <auro-alert type="error">Sorry, we can't process that right now. Your choice was not saved. Please try again later.</auro-alert>
              </div>
          )}
          <div className="choiceAlert">
            <auro-alert type="warning">Choose the benefit that is most meaningful to you. Please note that after you submit, you won't be able to change your selection.</auro-alert>
          </div>
          <br />
          <div className="album">
            <div className="container">
              <auro-radio-group required>
                <div className="row">
                  {ChoiceCardContent.map((data, index) => (
                      <div className="col-md-6 benefitCardWrapper d-flex align-items-stretch" key={index}>
                        <MPBenefitChoiceCard cData={data} text={undefined} setData={setData} />
                      </div>
                  ))}
                </div>
              </auro-radio-group>
            </div>
          </div>
          <MPBenefitChoiceButton selectedChoice={selectedChoice} setResultData={setResultData} />
          <MP100KAdditionalInfo />
        </div>
      </div>
  );
}

export default MP100KContainer;

