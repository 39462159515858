import "./index.scss";
import "@aurodesignsystem/auro-header";
import UserAccountContext from "../../context/UserAccountContext";
import { AccountStateType } from "../../state/InitialState";

import { useContext } from "react";
import {
  getMemberBalance,
  getMemberFirstName,
  getMileagePlanNumber,
} from "../../helpers/getMemberData";

const MPPerksContainer = () => {
  const { accountState }: { accountState: AccountStateType } =
    useContext(UserAccountContext);

  return (
    <div className="overviewPerksContainer">
      <div className="App container">
        <section className="mPPerksGuestOverview">
          <div className="mPPerksGuestOverview_content">
            <div className="mPPerksWrapper">
              <div className="mPPerksBody">
                <div className="mPPerksHeading">
                  <auro-header display="300" >
                    Perks
                  </auro-header>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default MPPerksContainer;